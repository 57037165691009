import { Roles } from '@app/core/enum/roles.enum';
import { AppRoute } from '../models';
export const Pages: AppRoute[] = [
  {
    title: $localize`:@@farmacii:Farmacii`,
    iconSrc: 'farmacii',
    routerLink: '/pharmacies',
    roles: [Roles.PHARMACY_OWNER, Roles.ADMIN, Roles.PHARMA],
    rolesRoute: {
      [Roles.ADMIN]: 'admin',
      [Roles.PHARMACY_OWNER]: 'pharma',
      [Roles.PHARMA]: 'pharma',
    },
  },
  {
    title: 'Furnizori',
    iconSrc: 'furnizor',
    routerLink: '/producatori',
    roles: [Roles.ADMIN],
  },
  {
    title: $localize`:@@medicamente:Medicamente`,
    iconSrc: 'medicamente',
    routerLink: '/drugs',
  },
  // {
  //   title: 'Abonamente',
  //   iconSrc: 'abonamente',
  //   routerLink: '/subscription',
  //   roles: [Roles.PHARMACY_OWNER, Roles.PHARMA, Roles.ADMIN],
  // },
  {
    title: $localize`:@@campanii:Campanii`,
    iconSrc: 'dashboard',
    routerLink: '/campaign',
    roles: [
      Roles.MANUFACTURER,
      Roles.MANUFACTURER_OWNER,
      Roles.ADMIN,
      Roles.PHARMA,
      Roles.PHARMACY_OWNER,
    ],
  },
  {
    title: 'Comenzile Mele',
    iconSrc: 'cash',
    routerLink: '/my-orders',
    roles: [
      Roles.MANUFACTURER,
      Roles.MANUFACTURER_OWNER,
      // Roles.ADMIN,
      // ^ admins should not have "my orders"
      Roles.PHARMA,
      Roles.PHARMACY_OWNER,
    ],
  },
  {
    title: 'Comenzi',
    iconSrc: 'storefront',
    routerLink: '/all-orders',
    roles: [Roles.ADMIN],
  },
  {
    title: 'Reduceri emise',
    iconSrc: 'discount',
    routerLink: '/issued-discounts',
    roles: [Roles.PHARMACY_OWNER],
  },
  {
    title: 'Facturile mele',
    iconSrc: 'receipt',
    routerLink: '/my-invoices',
    roles: [Roles.PHARMACY_OWNER],
  },
  {
    title: 'Facturi',
    iconSrc: 'documents',
    routerLink: '/all-invoices',
    roles: [Roles.ADMIN],
  },
  {
    title: 'Ordine de plata',
    iconSrc: 'bag-handle',
    routerLink: '/payment-orders',
    roles: [Roles.ADMIN],
  },
  {
    title: $localize`:@@administrareConturi:Administrare conturi`,
    iconSrc: 'user',
    routerLink: '/account-management',
  },
  {
    title: $localize`:@@mobile:Mobile`,
    iconSrc: 'mobile',
    routerLink: '/mobile',
    roles: [Roles.ADMIN],
  },
];

export const showMenuIfThesePaths: string[] = [
  '/pharmacies',
  '/drugs',
  '/drugs/edit',
  '/subscription',
  '/subscription/buy-plan',
  '/subscription/card-information',
  '/campaign',
  '/campaign/view-campaign',
  '/account-management',
  '/account-management/edit',
  '/account-management/add',
  '/settings',
  '/pharmacies/pharma',
  '/pharmacies/pharma/associate',
  '/pharmacies/pharma/edit',
  '/producatori',
  '/mobile',
  '/my-orders',
  '/all-orders',
  '/issued-discounts',
  '/my-invoices',
  '/all-invoices',
  '/payment-orders',
];
